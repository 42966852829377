import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

console.warn('👀👀👀 I see you are checking out the code.\n\nThe front end was built in React using create-react-app and react-bootstrap as a base. The "back end" is actually a Cloudflare worker using Cloudflare KV storage as a "database". \nDM on twitter if you have questions. Happy to chat about it! \nCheers \nSam.');
