import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { EventPage } from './pages/Event';
import { HomePage } from './pages/Home';



function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:id">
          <EventPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>

  );
}

export default App;
