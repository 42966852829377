import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { CountDown } from '../../components/countdown';
import { Footer } from '../../components/footer';
import { PageContainer } from '../../components/pageContainer';
import { Time } from '../../components/time';
import { getEvent, WIIAEvent } from '../../datasource';
import './Event.css';

function hasPassed(date: string): boolean {
  return Date.parse(date) < Date.now();
}

export const EventPage = () => {
  const [ticks, setTicks] = useState(0);
  const [data, setData] = useState<WIIAEvent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();


  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const event =  await getEvent(id);
      if (event) {
        setData(event);
      }
      setIsLoading(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    void getData();
  }, [id]);

  return (
    <PageContainer
      content={
        <div className="App">
          {(isLoading && !data) &&
            <>
              <div
                className="spinner-border text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </>}
          {(!isLoading && !data) &&
            <p>No event here. <Link to="/">Home?</Link></p>
          }
          {(!isLoading && data) &&
            <>
              <div className="name mb-3 px-4 w-sm-75 w-md-50">
                {data.name}
              </div>
              {data.description &&
                <div className="description mb-3 px-4 w-sm-75 w-md-50">
                  {data.description}
                </div>
              }
              <div className="mb-3 px-4">
                {!hasPassed(data.date) &&
                  <div className="mb-3">
                    <CountDown
                      time={data.date}
                      className="countdown"
                      onTick={() => setTicks(ticks => ticks += 1)}
                    />
                  </div>
                }
                <Time
                  isPast={hasPassed(data.date)}
                  time={data.date}
                  className="date"
                />

              </div>
              <div className="mb-3">
                <p>{`In your timezone (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</p>
              </div>

              {data.cta !== undefined &&
                <div className="description mb-3">
                  <Button
                    variant="outline-light"
                    onClick={() => { if (data.cta) { window.open(data.cta.link, '_blank'); } }}
                  >{data.cta.cta}</Button>
                </div>
              }
            </>
          }
        </div>
      }

      footer={<Footer showList />}
    />
  );
};