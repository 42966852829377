import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Footer } from '../../components/footer';
import { PageContainer } from '../../components/pageContainer';
import { CreatePage } from '../Create';

export const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>List an event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatePage onClose={handleClose} />
        </Modal.Body>
      </Modal>

      <PageContainer
        content={
          <div className="App">
            <h1>{'When\'s it at?'}</h1>
            <h5><span
              role="img"
              aria-label="sparkle"
            >✨</span> Helping you count down to global events in your time zone <span
              role="img"
              aria-label="sparkle"
            >✨</span></h5>
            <Button
              variant="outline-light"
              onClick={() => setShowModal(true)}
            >List an event</Button>
          </div>
        }

        footer={<Footer showList={false} />}
      />


    </>
  );
};