import moment from 'moment';
import React from 'react';

interface TimeProps {
  isPast: boolean;
  time: string;
  className: string;
}
const DATE_FORMAT = 'dddd, MMMM Do YYYY [at][\u00a0]h:mm a';

export const Time: React.FC<TimeProps> = ({ isPast, time, className }) => (
  <div className={className}>
    {isPast &&
      <span style={{ fontStyle: 'italic' }}>Was on </span>
    }
    {moment(time).format(DATE_FORMAT)}
  </div>
);
