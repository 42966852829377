import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


interface FooterProps {
  showList: boolean;
}
export const Footer: React.FC<FooterProps> = ({ showList }) => (
  <div className="px-4">
    <div className="footer px-4 text-center d-flex justify-content-center">
      {showList &&
        <div>Created by <a href="https://twitter.com/samjarman">Sam Jarman</a> • <Link to="/">List an event</Link></div>
      }{!showList &&
        <div>Created by <a href="https://twitter.com/samjarman">Sam Jarman</a></div>
      }
    </div>
    <i className="text-center d-block">When's it at? is not affiliated to the event in any way, shape or form. We're just helping you count down to it.</i>
  </div>
);