import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { default as Container, default as Form } from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import TimezoneSelect from 'react-timezone-select';
import '../../App.css';
import { createEvent, keyUsed } from '../../datasource';
import useDebounce from '../../hooks/useDebounce';
import './Create.css';

interface Timezone {
  value: string;
  label: string;
}

interface CreatePageProps {
  onClose: () => void;
}

export const CreatePage: React.FC<CreatePageProps> = ({ onClose }) => {
  const history = useHistory();

  const [date, setDate] = useState(new Date());
  const [key, setKey] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [challenge, setChallenge] = useState < null | string > (null);
  const userTz = moment.tz.guess();
  const [timezone, setTimezone] = useState<Timezone>({ value: userTz, label: userTz });
  const [invalidKey, setInvalidKey] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [link, setLink] = useState<string | undefined>();
  const [cta, setCta] = useState<string | undefined>();

  const debouncedKey = useDebounce(key, 100);

  useEffect(() => {
    const checkIfKeyUsed = async () => {
      const used = await keyUsed(debouncedKey);
      setInvalidKey(used);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    void checkIfKeyUsed();
  }, [debouncedKey]);


  useEffect(() => {
    setIsValid(!!key && !invalidKey && !!name && !!challenge);
  }, [key, name, invalidKey, challenge]);

  useEffect(() => {
    const newKey = key.replace(/[^a-z-]/gi, '');
    setKey(newKey);
  }, [key]);

  const performSubmit = async () => {
    if (!challenge) { return; }

    // Strip out the zone from the date, add in picked timezone.
    const zonedMoment = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), timezone.value);


    const callToAction = (cta !== undefined && link !== undefined) ? { cta: { cta, link } } : {};
    const result = await createEvent(key, challenge, { key, date: zonedMoment.utc().toISOString(), description, name, ...callToAction });
    if (result.success) {
      history.push('/' + key);
      return;
    }
    if (result.message === 'key_used') {
      setInvalidKey(true);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a name for the event (required)"
                value={name}
                onChange={v => setName(v.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter a description for the event (optional)"
                rows={2}
                value={description}
                onChange={v => setDescription(v.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Date and Time</Form.Label>
              <div className="form-control border">
                <DatePicker
                  selected={date}
                  dateFormat="d/M/yyyy, hh:mm a"
                  onChange={(date: Date) => setDate(date)}
                  showTimeInput
                  className="w-100 border-0"
                />
              </div>
            </Form.Group>

            <Form.Group>
              <label>Timezone</label>
              <TimezoneSelect
                value={timezone}
                onChange={(tz: Timezone) => setTimezone(tz)}
              />

            </Form.Group>

            <label htmlFor="basic-url">Link</label>
            <Form.Text className="text-muted">
              No spaces allowed. Keep it short and memorable.
            </Form.Text>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">
                  {(window.location.host + '/') || 'https://whensit.at/'}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                required
                className={classNames({ 'is-invalid': invalidKey })}
                aria-describedby="basic-addon3"
                onChange={v =>  setKey(v.target.value)}
                value={key}
              />
              <Form.Control.Feedback type="invalid">
                Link already used, please choose another
              </Form.Control.Feedback>
            </InputGroup>


            <Form.Group>
              <Form.Text className="text-muted">
                Optionally, you can add an external link to a live stream, blog post, online event, tickets etc.
              </Form.Text>
              <Form.Label>Button text</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg. Watch, Get Tickets, Learn more"
                value={cta || ''}
                onChange={event => setCta(event.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://...."
                value={link || ''}
                onChange={event => setLink(event.target.value)}
              />

            </Form.Group>


            <Form.Group>
              <ReCAPTCHA
                sitekey="6Ld3OsUZAAAAAPcmopfdGAA0trIAm7sSK3dDSm3u"
                onChange={(c: any) => setChallenge(c)}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              {/* <Button
                variant="outline-dark mr-2"
                disabled={!isValid}
                onClick={() => { onClose(); }}
              >
                Cancel
              </Button> */}
              <Button
                variant="primary"
                disabled={!isValid}
                onClick={() => { void performSubmit(); }}
              >
                List
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

    </Container>
  );
};

