import React, { ReactNode } from 'react';
import './PageContainer.css';

interface PageContainerProps {
  content: ReactNode;
  footer: ReactNode;
}

export const PageContainer: React.FC<PageContainerProps> = ({ content, footer }) => (
  <div className="page-container">
    <div className="page-footer-hidden">{footer}</div>
    <div>{content}</div>
    <div className="page-footer">{footer}</div>
  </div>
);