export interface WIIAEvent {
  key: string;
  name: string;
  date: string;
  description?: string;
  cta?: {
    cta: string;
    link: string;
  };
}

function apiEndpoint(): string {
  if (window.location.host === 'localhost:3000') {
    return 'http://localhost:8787';
  }
  return window.location.origin;
}

export async function getEvent(key: string): Promise<WIIAEvent | null> {
  // const local = localStorage.getItem(key);

  // if (local) {
  //   return JSON.parse(local) as WIIAEvent;
  // }

  const endpoint = `${apiEndpoint()}/api/event/${key}`;

  const remote = await fetch(endpoint).then(response => response.text()).catch(err => null);

  if (!remote) { return null; }

  localStorage.setItem(key, remote);
  return JSON.parse(remote) as WIIAEvent;
}

export async function keyUsed(key: string): Promise<boolean> {
  const event = await getEvent(key);
  if (event) { return true; }
  return false;
}

export interface WIIACreateResponse {
  success: boolean;
  message: string;
}

export async function createEvent(key: string, challenge: string, data: WIIAEvent): Promise<WIIACreateResponse> {
  const endpoint = `${apiEndpoint()}/api/event`;
  const body = JSON.stringify({ key, challenge, data });

  const response = await fetch(endpoint, { method: 'POST', body });

  if (!response.ok) {
    console.error(response);
  }
  const json = await response.json();
  return json;
}