import countdown from 'countdown';
import React, { useEffect, useState } from 'react';

interface CountDownProps {
  time: string;
  className: string;
  onTick: () => void;
}

export const CountDown: React.FC<CountDownProps> = ({ time, className, onTick }) => {
  const [label, setLabel] = useState<string>(countdown(null, new Date(time).valueOf()).toString());


  useEffect(() => {
    const timer = setInterval(() => {
      const separator = window.innerWidth < 576 ? ', \n' : ',';
      setLabel(countdown(null, new Date(time).valueOf()).toString().split(',').join(separator).split(' and ').join('\n and '));
      onTick();
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => { clearInterval(timer); };
  }, [time]);

  return (<div className={className}>{label.split('\n').map(part => <span key={part}>{part}<br /></span>)}</div>);
};

